import React, { Fragment, useContext } from 'react';
import { graphql, useStaticQuery,  Link } from 'gatsby';
import _map from 'lodash/map';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import { mq } from '../consts/style';

import ModalWindow from '../components/modal/modal-window'
import { useModalWithData } from '../hooks/modal-hook'

import {
  PageWrapper,
  PageInner,
  PageTitle,
  PostLink,
  Banner,
  Grid2Col,
  Spacer,
  ArrowDownLink,
  ArrowDownIcon,
  NavigationBottom,
  EquipeMembreTitle,
  Flex

} from '../components/Elements';


const FlexContent = styled.div`
display:flex;
align-items: flex-start;
justify-content:center; 
width:100%;
flex-wrap: wrap;
gap:4rem;
${mq.tabletSmall_up`
   flex-wrap: nowrap;
 `}
`

const FlexContentLeft = styled.div`
text-align:center;
`

const FlexContentRight = styled.div`
${mq.tabletSmall_up`
  margin-right:4rem;
 `}
`


 const CardEquipeWrapper = styled.div`
  display:flex;
  align-items: flex-start;
  justify-content:center; 
  width:100%;
  
  flex-wrap: wrap;
  ${mq.tabletSmall_up`
     flex-wrap: nowrap;
     gap:1rem;
   `}
`

 const CardEquipe = styled.div`
   display:flex;
   flex-direction:column;
   align-items: center;
   justify-content:center; 
   width:100%;
   margin-top:3rem;
  /*${mq.tabletSmall_up`
      width:50%;
   `}*/
   text-align:center;
   cursor:pointer;
  p {
    line-height: 25px;
    max-width: 100px;
  }

`
const PhotoMembre = styled(Img)`
  border-radius: 100%;
  filter:grayscale(1);
`

const blablaQuery = graphql`
  {
   
    banner: file(relativePath: { eq: "bandeau_bla-bla.png" }) {
        childImageSharp {
          fluid(maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    page: datoCmsPageBlaBla {
      titre
      lQuipe
      quiSommesNous
      vision
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      equipeMembres {
      membre {
        id
        nom
        titre
        description
        photo {
          fixed(width: 150, forceBlurhash: false, imgixParams: { fm: "jpg"}) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
    }
    }
  }
`;

export default function BlablaPage() {

  const data = useStaticQuery(blablaQuery);
  const { titre, lQuipe, quiSommesNous, vision, equipeMembres, seoMetaTags } = data.page;
  const {
    modalOpen,
    selected,
    setSelected,
    setModalState
  } = useModalWithData()
  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper>
        <Banner height="75px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
        <Grid2Col>
          <div>
              <PageTitle>Qui sommes-nous ?</PageTitle>
              <div dangerouslySetInnerHTML={{ __html: quiSommesNous }} />

              <br/>

              <PageTitle>L'équipe</PageTitle>
              <div dangerouslySetInnerHTML={{ __html: lQuipe }} />
              <ModalWindow  isActive={modalOpen}
          handleClose={() => setModalState(false)} 
            >     
            { (selected) &&
            <FlexContent>
                <FlexContentLeft>
                <PhotoMembre fixed={selected.membre.photo.fixed} />
              
                    <h3>{selected.membre.nom}</h3>
                    <p>  {selected.membre.titre}
                    </p>
                </FlexContentLeft>    
                <FlexContentRight  dangerouslySetInnerHTML={{ __html:selected.membre.description}}/>
            </FlexContent>
            }
            </ModalWindow> 
              <CardEquipeWrapper>
               {_map(data.page.equipeMembres, ( {membre} = membre.membre) => (
                <CardEquipe key={membre.id} onClick={() => {
                  setSelected({membre})
                  setModalState(true)}}>
                <PhotoMembre fixed={membre.photo.fixed} />
                  <EquipeMembreTitle>{membre.nom}</EquipeMembreTitle>
                  <p>
                    {membre.titre}
                  </p>

                </CardEquipe>
              ))}
              </CardEquipeWrapper>
            </div>
            <div>
              <PageTitle>Vision</PageTitle>
              <div dangerouslySetInnerHTML={{ __html: vision }} />
            </div>
        </Grid2Col>
        <Spacer/>
     
       {/* <NavigationBottom>
          <ArrowDownLink to="/projets" title="Projets">
            <ArrowDownIcon title="Aller à la page Projets" />
            Projets
          </ArrowDownLink>
        </NavigationBottom>*/}
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}
